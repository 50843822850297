<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Login v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <!--          <vuexy-logo/>-->
                    <img src="@/assets/images/ico/favicon.png" alt="logo" width="35%" height="35%" />
                    <!--          <h2 class="brand-text text-danger text-center">-->
                    <!--            Nojoum-->
                    <!--          </h2>-->
                </b-link>

                <b-card-title class="mb-1 text-center"> Welcome to Nojoum! 👋</b-card-title>
                <b-card-text class="mb-2 text-center"> Please sign-in to your account and start the adventure </b-card-text>

                <!-- form -->
                <validation-observer ref="loginForm" #default="{ invalid }">
                    <b-form class="auth-login-form mt-2" @submit.prevent="login">
                        <!-- email -->
                        <b-form-group label-for="email" label="Email">
                            <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                                <b-form-input id="email" v-model="userEmail" name="login-email" :state="errors.length > 0 ? false : null" placeholder="john@example.com" autofocus />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <label for="password">Password</label>
                                <b-link :to="{ name: 'auth-forgot-password' }">
                                    <small>Forgot Password?</small>
                                </b-link>
                            </div>
                            <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                                    <b-form-input id="password" v-model="password" :type="passwordFieldType" class="form-control-merge" :state="errors.length > 0 ? false : null" name="login-password" placeholder="Password" />

                                    <b-input-group-append is-text>
                                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button variant="primary" type="submit" block :disabled="invalid"> Sign in </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <span>New on our platform? </span>
                    <b-link :to="{ name: 'auth-register' }">
                        <span>Create an account</span>
                    </b-link>
                </b-card-text>

                <!--        <div class="divider my-2">-->
                <!--          <div class="divider-text">-->
                <!--            or-->
                <!--          </div>-->
                <!--        </div>-->

                <!-- social button -->
                <!--        <div class="auth-footer-btn d-flex justify-content-center">-->
                <!--          <b-button-->
                <!--              href="javascript:void(0)"-->
                <!--              variant="facebook"-->
                <!--          >-->
                <!--            <feather-icon icon="FacebookIcon"/>-->
                <!--          </b-button>-->
                <!--          <b-button-->
                <!--              href="javascript:void(0)"-->
                <!--              variant="twitter"-->
                <!--          >-->
                <!--            <feather-icon icon="TwitterIcon"/>-->
                <!--          </b-button>-->
                <!--          <b-button-->
                <!--              href="javascript:void(0)"-->
                <!--              variant="google"-->
                <!--          >-->
                <!--            <feather-icon icon="MailIcon"/>-->
                <!--          </b-button>-->
                <!--          <b-button-->
                <!--              href="javascript:void(0)"-->
                <!--              variant="github"-->
                <!--          >-->
                <!--            <feather-icon icon="GithubIcon"/>-->
                <!--          </b-button>-->
                <!--        </div>-->
            </b-card>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BAlert, VBTooltip } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    directives: {
        "b-tooltip": VBTooltip,
    },
    components: {
        // BSV
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        BAlert,
        VuexyLogo,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            userEmail: "",
            password: "",
            status: "",
            sideImg: require("@/assets/images/pages/login-v2.svg"),
            // validation rules
            required,
            email,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
    },
    methods: {
        login() {
            this.$refs.loginForm.validate().then((success) => {
                if (success) {
                    useJwt
                        .login({
                            username: this.userEmail,
                            password: this.password,
                        })
                        .then((response) => {
                            const { user, token } = response.data.data;
                            // user.role = 'admin'
                            user.ability = [
                                {
                                    action: "manage",
                                    subject: "all",
                                },
                            ];
                            useJwt.setToken(token);
                            useJwt.setRefreshToken(token);

                            // set data to localstorage
                            localStorage.setItem("userData", JSON.stringify(user));

                            //set data to cookie
                            // let d = new Date();
                            // d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                            // let expires = "expires=" + d.toUTCString();
                            // document.cookie = "userData=" + JSON.stringify(user) + ";" + expires + ";path=/";
                            //
                            //set data to cookie2
                            // this.$cookie.set("userData", JSON.stringify(user), d);
                            //

                            this.$ability.update(user.ability);

                            // ? This is just for demo purpose as well.
                            // ? Because we are showing eCommerce app's cart items count in navbar
                            // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                            // this.$router.replace(getHomeRouteForLoggedInUser(user.role)).then(() => {
                            //     this.$toast({
                            //         component: ToastificationContent,
                            //         position: "top-right",
                            //         props: {
                            //             title: `Welcome ${user.name}`,
                            //             icon: "CoffeeIcon",
                            //             variant: "success",
                            //             text: `You have successfully logged in as ${user.role}. Now you can start to explore!`,
                            //         },
                            //     });
                            // });
                            this.$router.replace("/").then(() => {
                                this.$toast({
                                    component: ToastificationContent,
                                    position: "top-right",
                                    props: {
                                        title: `Welcome ${user.name}`,
                                        icon: "CoffeeIcon",
                                        variant: "success",
                                        text: `You have successfully logged in as ${user.role}. Now you can start to explore!`,
                                    },
                                });
                            });
                        })
                        .catch((error) => {
                            this.$refs.loginForm.setErrors(error.response.data.error);
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
